<template>
  <b-container class="pt-2 mt-5">
    <loading-spinner class="mt-5" v-if="loading"/>
    <div v-else>
      <div class="d-flex justify-content-center">
        <h3>Вход</h3>
      </div>
      <b-card class="mt-3 d-block mx-auto login-card">
        <template v-if="authType === 'OTP_AND_LOGIN_PASSWORD'" #header>
          <b-nav card-header tabs small fill>
            <b-nav-item :class="{'pill-active': authUsingOTP}" @click="authUsingOTP = true"
                        :active="authUsingOTP">
              Номер телефона
            </b-nav-item>
            <b-nav-item :class="{'pill-active': !authUsingOTP}" @click="authUsingOTP = false"
                        :active="!authUsingOTP">
              Логин и пароль
            </b-nav-item>
          </b-nav>
        </template>
        <b-card-body v-if="authType === 'OTP_AND_LOGIN_PASSWORD'">
          <otp-auth v-if="authUsingOTP"/>
          <login-password-auth v-else/>
        </b-card-body>
        <otp-auth v-else-if="authType === 'OTP'"/>
        <login-password-auth v-else/>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import OTPAuth from "@/components/OTPAuth";
import LoginPasswordAuth from "@/components/LoginPasswordAuth";
import authService from "@/modules/auth";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: {
    'otp-auth': OTPAuth,
    'login-password-auth': LoginPasswordAuth,
    'loading-spinner': LoadingSpinner
  },
  name: 'login',
  data() {
    return {
      authType: null,
      authUsingOTP: true,
      loading: false,
    };
  },
  created() {
    this.loading = true;
    authService.getAuthType()
        .then(response => {
          this.authType = response.data;
        })
        .catch(() => {
          this.authType = "LOGIN_PASSWORD";
        })
        .finally(() => this.loading = false);
  }
}
</script>
<style scoped>
@media only screen and (width > 768px) {
  .login-card {
    width: 45%;
  }
}
.pill-active a {
  color: #FDA701FF !important;
}

/deep/ .nav-item a {
  color: #495057;
}
</style>
