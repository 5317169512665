import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login.vue';
import FeedList from '@/views/feed/FeedList';
import FeedItemEdit from '@/views/feed/FeedItemEdit';
import store from "@/store";
Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/login', component: Login,  meta: {title: 'Вход'}},
	    {path: '/', component: FeedList, meta: { title: 'Список уведомлений'}},
        {path: '/create', component: FeedItemEdit, meta: { title: 'Создание уведомления'}, props: {creating: true}},
        {path: '/:id', component: FeedItemEdit, meta: { title: 'Редактирование уведомления'}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }

    if (!store.getters.isAuthorized && '/login' !== to.path) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === to.path) {
        return next('/');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/' !== to.path) {
        return next('/');
    }
    if (!store.getters.isAdmin && '/create' === to.path) {
        return next('/');
    }
    return next();
});

export default router;
